// vendor styles
import '../../custom/css/bootstrap.min.css';
import '../../custom/css/responsive.css';
import '../../custom/css/photoswipe.css';
import '../../custom/css/default-skin/default-skin.css';
import '../../custom/css/slick.css';
import '../../custom/css/slick-theme.css';
import '../../custom/css/colors/color1.css';
import '../../custom/css/animate.css';
import '../../custom/css/colors.css';

// vendor js

import '../../custom/js/jquery-ui.js';
import '../../custom/js/jquery.easing.js';
import '../../custom/js/jquery.flexslider-min.js';
import '../../custom/js/owl.carousel.js';
import '../../custom/js/imagesloaded.min.js';
import '../../custom/js/jquery-countTo.js';
import '../../custom/js/jquery-validate.js';
import '../../custom/js/jquery.mb.YTPlayer.js';
import '../../custom/js/jquery.cookie.js';
import '../../custom/js/jquery.fitvids.js';
import '../../custom/js/main.js';
import '../../custom/js/jquery.themepunch.revolution.min.js';
import '../../custom/js/slider.js';
import '../../custom/js/sticky-kit.min.js';
import '../../custom/js/for-form/khaki.js';
import '../../custom/js/for-form/khaki-init.js';